<template>
  <section class="container">
    <div class="register"
         :class="{'padding-webview' : checkWebview(), 'padding-has-caution': checkMobile()}">
      <h3 class="title">ログイン</h3>

      <div class="register__container step">

          <div class="step__register">

            <ValidationObserver
              tag="form"
              ref="observer"
              @submit.prevent="validateBeforeSubmit()"
              class="step__block step__block--mail">

              <h2>メールアドレスでログイン</h2>

              <ul v-if="isCountDown"
                  class="error__server">
                <li>アカウントがロックされました。{{countDown}}秒後に再度お試しください。</li>
              </ul>

              <ul v-if="errors && !isGoogleLoginError && !isYahooLoginError"
                  class="error__server">
                <li v-html="errors"></li>
              </ul>

              <InputField v-model="form.email"
                          field="メールアドレス"
                          placeholder="メールアドレス"
                          rules="required|email|max:200"
                          class="mb-3"
                          vid="email"
                          :prefix="true">

                <template #prefix>
                  <MailIcon size="22"
                            class="prefix"/>
                </template>
              </InputField>

              <InputField v-model="form.password"
                          field="パスワード"
                          type="password"
                          placeholder="パスワード"
                          rules="required|minmax:8,32"
                          class="mb-3"
                          vid="password"
                          :prefix="true">

                <template #prefix>
                  <lock-icon size="1.5x"
                             class="prefix"/>
                </template>
              </InputField>

              <div class="remember__me">
                <input type="checkbox" id="rememberMe" v-model="form.is_remember" :true-value="1" :false-value="0">
                <label for="rememberMe">次回から自動でログイン</label>
              </div>

              <button class="btn__register" type="submit" :disabled="isCountDown">
                ログイン
              </button>

              <router-link :to="{name: 'ForgotPassword'}">パスワードをお忘れの場合はこちら</router-link>

            </ValidationObserver>

            <p class="txt__or">または</p>

            <div class="step__block step__block--sn">

              <h2>SNS連携</h2>

              <!-- button login yahoo -->
              <button @click.prevent="handleRedirectSNS('yahoo')"
                      class="btn__register btn__register--sn" :disabled="isCountDown">
                <span class="icon">
                  <img src="@/assets/images/icon/icon-yahoo.svg" alt="icon">
                </span>
                Yahoo! Japan ID でログインする
              </button>

              <!-- button login google -->
              <button @click.prevent="handleRedirectSNS('google')"
                      class="btn__register btn__register--sn" :disabled="isCountDown">
                <span class="icon">
                  <img src="@/assets/images/icon/icon-google.svg" alt="icon">
                </span>
                Google アカウントでログインする
              </button>

              <!-- button login line -->
              <button @click.prevent="handleRedirectSNS('line')"
                      class="btn__register btn__register--sn" :disabled="isCountDown">
                <span class="icon">
                  <img src="@/assets/images/icon/icon-line.svg" alt="icon">
                </span>
                LINE アカウントでログインする
              </button>

              <!-- line account registered error message -->
              <ul v-if="lineRegisteredMessageError"
                  class="error__server">
                <li v-html="lineRegisteredMessageError"></li>
              </ul>

              <!-- google, yahoo login error message -->
              <ul v-if="errors && (isGoogleLoginError || isYahooLoginError)"
                  class="error__server">
                <li>{{ errors }}</li>
              </ul>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
// Store
import store from '@/store'
import { mapActions } from 'vuex'
// Component
import InputField from '@/components/form/InputField'
import FormMixin from '@/mixins/form.mixin'
import Common from '@/mixins/common.mixin'
import { getExchangeServiceRoutes } from '@/utils/exchange-service'
import { RESPONSE_STATUS } from '@/enum/response'
// Icon
import { MailIcon, LockIcon } from 'vue-feather-icons'
import { giftCodeStorageKey } from '@/enum/gift-code'
import Cookie from 'js-cookie'

export default {
  name: 'Login',

  mixins: [FormMixin, Common],

  components: {
    InputField,
    MailIcon,
    LockIcon
  },

  data () {
    return {
      form: {
        with: 'user',
        email: '',
        password: '',
        is_remember: 0
      },
      errors: null,
      lineRegisteredMessageError: null,
      isGoogleLoginError: false,
      isYahooLoginError: false,
      countDown: 60,
      isCountDown: false
    }
  },

  beforeRouteEnter (to, from, next) {
    // check route history to back page AdvertisingDetail
    const exchangeServiceRoutes = getExchangeServiceRoutes()
    const arrRouterHistory = ['AdvertisingDetail', ...exchangeServiceRoutes]

    if (from.name && arrRouterHistory.includes(from.name)) {
      to.meta.routeHistory = from.name
      localStorage.setItem('route-history', from.path)
    }

    // check call back dot money
    if (to.query.callback) {
      const searchParams = new URL(to.query.callback).searchParams
      const loginFlag = searchParams.get('login_flag')
      const callbackState = searchParams.get('callbackstate')
      if (loginFlag === '1' && callbackState !== null) {
        sessionStorage.setItem('callBackDotMoney', to.query.callback)
      }
    }
    next()
  },

  beforeRouteLeave (to, from, next) {
    store.commit('auth/SET_MESSAGE_ERR', null)
    store.commit('auth/SET_LINE_REGISTERED_MESSAGE_ERR', null)
    next()
  },

  created () {
    if (sessionStorage.getItem('previous_route')) {
      sessionStorage.removeItem('previous_route')
    }

    // feature-list in top native
    if (this.checkWebview()) {
      !Cookie.get('bearer_token') ? this.openPageNative('Login') : this.openPageNative('Index')
    }
  },

  mounted () {
    this.getMessageErrors()
  },

  methods: {
    ...mapActions('auth', ['userLogin', 'redirectUserSNS']),
    ...mapActions('dotMoney', ['loginDotMoney', 'confirmExchange']),
    ...mapActions('dohBo', ['handleDohBoParam']),

    async getMessageErrors () {
      this.errors = await this.$store.state.auth.messErr
      this.isGoogleLoginError = await this.$store.state.auth.isGoogleLoginError
      this.isYahooLoginError = await this.$store.state.auth.isYahooLoginError
      this.lineRegisteredMessageError = await this.$store.state.auth.lineRegisteredMessError
    },

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.handleSubmit()
      }
    },

    async handleSubmit () {
      // handle API LOGIN
      const loginResponse = await this.userLogin(this.form)

      if (loginResponse.success) {
        const dohBoParams = this.getDohBoParams()
        const hasDohBoParams = Object.keys(dohBoParams).length !== 0

        if (hasDohBoParams) {
          const handleParamResponse = await this.handleDohBoParam(dohBoParams)

          if (handleParamResponse.status === RESPONSE_STATUS.OK) {
            this.removeDohBoParams(dohBoParams)
          }
        }

        const exchangeServiceRoutes = getExchangeServiceRoutes()
        // check route has back history
        const arrRouterHistory = ['AdvertisingDetail', ...exchangeServiceRoutes]

        // check route back from query 'from'
        const queryFrom = this.$route.query.from

        if (this.$route.meta.routeHistory && arrRouterHistory.includes(this.$route.meta.routeHistory)) {
          this.$router.go(-1)
          this.$route.meta.routeHistory = null
        } else if (sessionStorage.getItem('callBackDotMoney') !== null) {
          const urlRedirect = sessionStorage.getItem('callBackDotMoney')
          sessionStorage.removeItem('callBackDotMoney')

          const exchangeDotMoneyResponse = await this.confirmExchange()

          if (loginResponse.status === RESPONSE_STATUS.OK) {
            const { status_code: statusCode } = exchangeDotMoneyResponse.data

            if (statusCode === RESPONSE_STATUS.OK) {
              this.loginDotMoney().then((res) => {
                window.open(res.data, '_self')
              })
            } else if (statusCode === RESPONSE_STATUS.NOT_FOUND) {
              window.open(urlRedirect, '_self')
            }
          }
        } else if (queryFrom) {
          this.$router.push(queryFrom)
        } else {
          const giftCodeData = Cookie.get(giftCodeStorageKey) || null
          if (giftCodeData) {
            this.$router.push({
              name: 'GiftCodeDetail',
              params: {
                code: giftCodeData
              }
            })
          } else {
            this.$router.push({ name: 'Profile.Mypage' })
          }
        }
      } else {
        this.lineRegisteredMessageError = null
        this.isGoogleLoginError = false
        this.isYahooLoginError = false
        this.errors = null

        if (loginResponse.code === RESPONSE_STATUS.UNPROCESSABLE_CONTENT) {
          this.errors = loginResponse.errors[0].message[0]
        } else if (loginResponse.status === RESPONSE_STATUS.TOO_MANY_REQUEST) {
          this.setCountDownTimer()
        } else {
          this.errors = loginResponse.error.message
        }
      }
    },

    setCountDownTimer () {
      this.countDown = 60
      this.isCountDown = true
      this.countDownTimer()
    },

    async handleRedirectSNS (service = '') {
      const snsResponse = await this.redirectUserSNS([service])

      if (this.$route.query.invite) {
        sessionStorage.setItem('invite_code', this.$route.query.invite)
      } else {
        sessionStorage.removeItem('invite_code')
      }

      sessionStorage.setItem('previous_route', this.$route.name)

      if (snsResponse.success) {
        this.openRedirectSNS(snsResponse.data.url)
      } else if (snsResponse.status === RESPONSE_STATUS.TOO_MANY_REQUEST) {
        this.setCountDownTimer()
      }
    },

    openRedirectSNS (url) {
      const link = document.createElement('a')
      link.setAttribute('href', url)
      document.body.appendChild(link)
      link.click()
    },

    countDownTimer () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.isCountDown = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.register {
  padding: 90px 0;
  @media #{$info-phone} {
    padding: 85px 0;
  }
  &.padding-has-caution {
    @media #{$info-phone} {
      padding: 110px 0 85px;
    }
  }
  &.padding-webview {
    padding: 40px 0;
  }
  .title {
    font-family: $font-family-title;
    color: $default-green;
    @include font-size(36px);
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
    @media #{$info-phone} {
      font-size: 32px;
      margin-bottom: 30px;
    }
  }
  &__container {
    border: 6px solid #E7E5DE;
    border-radius: 6px;
    background-color: #FAFAFA;
    @media #{$info-phone} {
      border: 3px solid #E7E5DE;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
  }
  .step {
    padding: 65px;
    @media #{$info-phone} {
      border-bottom: 1px solid #CACBC6;
      padding: 48px 18px;
    }
    &__register {
      display: flex;
      @media #{$info-phone} {
        display: block;
      }
      .txt__or {
        flex-shrink: 0;
        margin: 0 12px;
        align-self: center;
        @media #{$info-phone} {
          align-self: unset;
          text-align: center;
          padding: 20px 0;
        }
      }
      .count-down {
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px;
        color: #cd201f;
      }
      button[disabled] {
        background: #f5f5f5;
        color: rgba(0,0,0,.25);
        cursor: not-allowed;
      }
    }
    &__block {
      background-color: #E7E5DE;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      padding: 22px;
      @media #{$info-phone} {
        padding: 18px;
      }

      h2 {
        @include font-size(18px);
        margin-bottom: 20px;
      }
    }
    &__block--mail {
      width: 55%;
      @media #{$info-phone} {
        width: 100%;
      }
      .remember__me {
        display: flex;
        align-items: center;
        justify-content: center;
        label {
          margin-left: 5px;
          @include font-size(12px);
          line-height: 1;
        }
        input {
          width: 16px;
          height: 16px;
        }
      }
      .btn__register {
        background-color: #6F8784;
        color: #ffffff;
        border-radius: 6px;
        @include font-size(18px);
        font-family: $font-family-title;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 14px;
        cursor: pointer;
        margin-top: 16px;
        margin-bottom: 15px;
        @media #{$info-phone} {
          margin-top: 12px;
          margin-bottom: 15px;
          padding: 9px 14px;
          font-size: 16px;
        }
      }

      a {
        @include font-size(12px);
        display: block;
        text-align: center;
        text-decoration: underline;
      }
    }
    &__block--sn {
      width: 45%;
      background-color: #E7E8DE;
      @media #{$info-phone} {
        width: 100%;
      }

      h2 {
        margin-bottom: 35px;
        @media #{$info-phone} {
          margin-bottom: 15px;
        }
      }

      button {
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0 1px 0 #BEBFB9;
        border-radius: 6px;
        @include font-size(18px);
        font-family: $font-family-title;
        display: flex;
        align-items: center;
        padding: 14px;
        cursor: pointer;
        @media #{$info-phone} {
          font-size: 14px;
          padding: 9px 14px;
        }
        .icon {
          display: inline-flex;
          margin-right: 10px;
          max-width: 26px;
          width: 100%;
        }
      }
      button:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    &__registered {
      background-color: #E7E5DE;
      border-radius: 6px;
      padding: 36px 108px;
      @media #{$info-phone} {
        padding: 24px 18px;
      }
      .mail {
        background-color: #ffffff;
        text-align: center;
        border-radius: 6px;
        margin-bottom: 25px;
        font-family: $font-family-title;
        color: $default-color;
        padding: 15px;
        @media #{$info-phone} {
          font-size: 16px;
          padding: 10px 15px;
          text-align: left;
          img {
            margin-right: 10px;
            width: 20px;
          }
        }
      }
      ul {
        li {
          line-height: 28px;
          font-weight: 500;
        }
      }
      .note01 {
        border-bottom: 1px solid #ffffff;
        padding-bottom: 28px;
        margin-bottom: 28px;
        @media #{$info-phone} {
          padding-bottom: 24px;
          margin-bottom: 24px;
        }
      }
    }
  }
}
@media #{$info-phone} {
  .container {
    padding: 0;
  }
}
</style>
